<template>
  <div class="home">
    <!-- https://github.com/brendanashworth/generate-password -->
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title">Generate Secure Password</h1>
          <form>

            <div class="mb-3 row">
              <label for="length" class="col-7 col-sm-6 col-md-6 col-lg-4 col-form-label">Select Length</label>
              <div class="col-5 col-sm-6 col-md-6 col-lg-8">
                <select v-model="length" class="form-select" id="length" required @change="generate_password">
                  <option value="">Choose...</option>
                  <optgroup label="Weak">
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </optgroup>
                  <optgroup label="Mediam">
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </optgroup>
                  <optgroup label="Strong">
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                  </optgroup>
                  
                </select>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-7 col-sm-6 col-md-6 col-lg-4 col-form-label">Uppercase</label>
              <div class="col-5 col-sm-6 col-md-6 col-lg-8">
                <input class="form-check-input" type="checkbox" :checked="uppercase" v-model="uppercase" @change="generate_password">
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-7 col-sm-6 col-md-6 col-lg-4 col-form-label">Numbers</label>
              <div class="col-5 col-sm-6 col-md-6 col-lg-8">
                <input class="form-check-input" type="checkbox" :checked="numbers" v-model="numbers" @change="generate_password">
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-7 col-sm-6 col-md-6 col-lg-4 col-form-label">Symbols</label>
              <div class="col-5 col-sm-6 col-md-6 col-lg-8">
                <input class="form-check-input" type="checkbox" :checked="symbols" v-model="symbols" @change="generate_password">
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-7 col-sm-6 col-md-6 col-lg-4 col-form-label">Exclude similar characters</label>
              <div class="col-5 col-sm-6 col-md-6 col-lg-8">
                <input class="form-check-input" type="checkbox" :checked="excludeSimilarCharacters" v-model="excludeSimilarCharacters" @change="generate_password">
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-7 col-sm-6 col-md-6 col-lg-4 col-form-label">Strict</label>
              <div class="col-5 col-sm-6 col-md-6 col-lg-8">
                <input class="form-check-input" type="checkbox" :checked="strict" v-model="strict" @change="generate_password">
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-12 col-sm-12 col-md-12 col-lg-4 col-form-label">Your password</label>
              <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                <div class="input-group input-group-sm">
                  <input class="form-control" @focus="$event.target.select()" ref="myPass" :value="password" readonly>
                  <div class="input-group-text">
                    <button type="button" class="btn btn-primary btn-sm" @click="copyPass" ref="copyBtn">Copy</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-2 col-md-4 col-lg-4 col-form-label">&nbsp;</label>
              <div class="col-10 col-sm-8 col-md-8 col-lg-8">
                <button type="button" class="btn btn-primary mb-3" @click="generate_password">Re-generate password</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

const generator = require('generate-password');

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      length: 8,
      uppercase: true,
      numbers: true,
      symbols: true,
      lowercase: true,
      excludeSimilarCharacters: true,
      strict: true,
      password: ""
    }
  },
  created() {
    this.generate_password();
  },
  methods: {
    generate_password() {
      this.password = generator.generate({
        length: this.length,
        uppercase: this.uppercase,
        numbers: this.numbers,
        symbols: this.symbols,
        lowercase: this.lowercase,
        excludeSimilarCharacters: this.excludeSimilarCharacters,
        strict: this.strict
      });
    },
    copyPass() {
      console.log("copy");
      this.$refs.myPass.focus();
      document.execCommand('copy');
      this.$refs.copyBtn.focus();
    }
  }
}
</script>
