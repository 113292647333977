<template>
  <div>
    <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span class="fs-4">Password Generator</span>
      </a>

      <!-- <ul class="nav nav-pills">
          <li class="nav-item">
            <router-link class="nav-link active" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
      </ul> -->
    </header>
    
  </div>
  <router-view/>

  <div>
    <footer class="py-3 my-4">
      <!-- <ul class="nav justify-content-center border-bottom pb-3 mb-3">
        <li class="nav-item">
            <router-link class="nav-link active" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
      </ul> -->
      <p class="text-center text-muted">&copy; 2022 SacredInfosystem.com</p>
    </footer>
  </div>
</template>

<style>

</style>
